<template>
  <div
    v-show="open"
    :ref="id + 'confirmModal'"
    data-backdrop="static"
    data-keyboard="false"
    :data-cy="id + '-modal'"
    class="modal fade hide"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
    style="display: block"
  >
    <div class="modal-dialog" :class="{ 'modal-lg': lgSize || size === 'lg', 'modal-md': size === 'md' }" role="document">
      <div class="modal-content">
        <div class="modal-header align-items-center">
          <h4 class="modal-title">{{ title }}</h4>
          <button type="button" class="btn btn-text-dark" :disabled="loading" @click.prevent="onClose">
            <span aria-hidden="true">
              <i class="fa fa-lg fa-times"></i>
            </span>
          </button>
        </div>
        <div class="modal-body pb-1">
          <slot><p v-html="text"></p></slot>
        </div>
        <div class="modal-footer">
          <slot name="footer">
            <i v-show="loading" class="fa fa-spinner fa-spin"></i>
            <button :data-cy="`${id}-close`" class="btn btn-light" type="button" :disabled="loading" @click.prevent="onClose">Close</button>
            <button v-if="!hideSubmit" :data-cy="`${id}-submit`" class="btn btn-primary" type="submit" :disabled="loading" @click.prevent="onSubmit">
              {{ loading ? 'Loading...' : confirmText }}
            </button>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ConfirmModal',
  props: {
    open: {
      type: [Boolean, Object],
      default: false,
      required: false
    },
    title: {
      type: String,
      default: 'Confirm'
    },
    text: {
      type: String,
      default: 'Are you sure?'
    },
    loading: {
      type: Boolean,
      default: false
    },
    prevent: {
      type: Boolean,
      default: false
    },
    lgSize: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: null
    },
    hideSubmit: {
      type: Boolean,
      default: false
    },
    confirmText: {
      type: String,
      default: 'Confirm'
    },
    id: {
      type: String,
      default: ''
    }
  },
  watch: {
    open(isShow) {
      jQuery(this.$refs[this.id + 'confirmModal']).modal(isShow ? 'show' : 'hide');
    }
  },
  beforeDestroy() {
    jQuery(this.$refs[this.id + 'confirmModal']).modal('hide');
  },
  mounted() {
    jQuery(this.$refs[this.id + 'confirmModal']).on('hidden.bs.modal', () => {
      this.$emit('close');
    });
  },
  methods: {
    onSubmit() {
      this.$emit('submit');
      if (!this.prevent) this.$emit('close');
    },
    onClose() {
      this.$emit('close');
    }
  }
};
</script>
