var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.open,
      expression: "open"
    }],
    ref: _vm.id + 'confirmModal',
    staticClass: "modal fade hide",
    staticStyle: {
      "display": "block"
    },
    attrs: {
      "data-backdrop": "static",
      "data-keyboard": "false",
      "data-cy": _vm.id + '-modal',
      "tabindex": "-1",
      "role": "dialog",
      "aria-hidden": "true"
    }
  }, [_c('div', {
    staticClass: "modal-dialog",
    class: {
      'modal-lg': _vm.lgSize || _vm.size === 'lg',
      'modal-md': _vm.size === 'md'
    },
    attrs: {
      "role": "document"
    }
  }, [_c('div', {
    staticClass: "modal-content"
  }, [_c('div', {
    staticClass: "modal-header align-items-center"
  }, [_c('h4', {
    staticClass: "modal-title"
  }, [_vm._v(_vm._s(_vm.title))]), _c('button', {
    staticClass: "btn btn-text-dark",
    attrs: {
      "type": "button",
      "disabled": _vm.loading
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.onClose($event);
      }
    }
  }, [_vm._m(0)])]), _c('div', {
    staticClass: "modal-body pb-1"
  }, [_vm._t("default", [_c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.text)
    }
  })])], 2), _c('div', {
    staticClass: "modal-footer"
  }, [_vm._t("footer", [_c('i', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "fa fa-spinner fa-spin"
  }), _c('button', {
    staticClass: "btn btn-light",
    attrs: {
      "data-cy": "".concat(_vm.id, "-close"),
      "type": "button",
      "disabled": _vm.loading
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.onClose($event);
      }
    }
  }, [_vm._v("Close")]), !_vm.hideSubmit ? _c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "data-cy": "".concat(_vm.id, "-submit"),
      "type": "submit",
      "disabled": _vm.loading
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.onSubmit($event);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.loading ? 'Loading...' : _vm.confirmText) + " ")]) : _vm._e()])], 2)])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    attrs: {
      "aria-hidden": "true"
    }
  }, [_c('i', {
    staticClass: "fa fa-lg fa-times"
  })]);
}]

export { render, staticRenderFns }